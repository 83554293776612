@tailwind base;
@tailwind components;

/*Linea en tabla*/
body{
    background-color: #F3F4F6;
    /*overflow: hidden;*/
}
/*

lineas en gastos

.date:after {
    content: "";
    position: absolute;
    border-top: 1px solid #e2e8f0;
    top: 12px;
    width: 150px;
  }
  
  .date:after {
    margin-left: 15px;
  }*/


  .boton-flotante{
    position: fixed;
	bottom: 40px;
	right: 40px;
  }

  /*modal*/

.modal { /*fondo popup*/
  background: rgba(0, 0, 0, 0.8);
}

.modal-error-mensaje{ /*fonde de mensajes y error*/
  position: fixed;
  top: 0;
  left: 0;
  width:100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
}
/*
.modal-main {
  position:fixed;
  background: white;
  padding: 2%;
  width: 90%;
  height: auto;
  top:50%;
  left:50%;
  transform: translate(-50%,-50%);
}

.display-block {
  display: block;
}

.display-none {
  display: none;
}*/

/*loading*/
/*LOADING*/

.loader-dots div {
  animation-timing-function: cubic-bezier(0, 1, 1, 0);
}
.loader-dots div:nth-child(1) {
  left: 8px;
  animation: loader-dots1 0.6s infinite;
}
.loader-dots div:nth-child(2) {
  left: 8px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(3) {
  left: 32px;
  animation: loader-dots2 0.6s infinite;
}
.loader-dots div:nth-child(4) {
  left: 56px;
  animation: loader-dots3 0.6s infinite;
}
@keyframes loader-dots1 {
  0% {
      transform: scale(0);
  }
  100% {
      transform: scale(1);
  }
}
@keyframes loader-dots3 {
  0% {
      transform: scale(1);
  }
  100% {
      transform: scale(0);
  }
}
@keyframes loader-dots2 {
  0% {
      transform: translate(0, 0);
  }
  100% {
      transform: translate(24px, 0);
  }
}

@tailwind utilities;